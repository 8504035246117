import React, { useState } from 'react';

const Card = ({ judul, ekstensi, url, tags, catatan, onEdit, onTagClick, onExtensionClick }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({
    judul,
    ekstensi,
    url,
    tags: tags.join(', '),
    catatan,
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData({
      ...editedData,
      [name]: value,
    });

    if (name === 'catatan' && value.length > 200) {
      setErrors({ ...errors, catatan: 'Catatan tidak boleh lebih dari 200 karakter.' });
    } else if (name === 'url') {
      const isValidUrl = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
      if (!isValidUrl) {
        setErrors({ ...errors, url: 'URL harus bisa diakses publik.' });
      } else {
        setErrors({ ...errors, url: '' });
      }
    } else {
      setErrors({ ...errors, [name]: '' });
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    if (Object.values(errors).every(error => error === '')) {
      const updatedData = {
        ...editedData,
        tags: editedData.tags.split(',').map(tag => tag.trim()),
      };
      onEdit(updatedData);
      setIsEditing(false);
    }
  };

  return (
    <div className="card">
      {isEditing ? (
        <div>
          <input
            type="text"
            name="judul"
            value={editedData.judul}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="ekstensi"
            value={editedData.ekstensi}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="url"
            value={editedData.url}
            onChange={handleInputChange}
            placeholder="URL harus bisa diakses publik"
          />
          {errors.url && <p className="error">{errors.url}</p>}
          <input
            type="text"
            name="tags"
            value={editedData.tags}
            onChange={handleInputChange}
          />
          <textarea
            name="catatan"
            value={editedData.catatan}
            onChange={handleInputChange}
            placeholder="Catatan tidak boleh lebih dari 200 karakter"
          />
          {errors.catatan && <p className="error">{errors.catatan}</p>}
          <button onClick={handleSaveClick}>Save</button>
        </div>
      ) : (
        <>
          <strong>{judul}</strong>
          <p>
            Ekstensi: <span className="extension" onClick={() => onExtensionClick(ekstensi)}>{ekstensi}</span>
          </p>
          <p>
            Tags: {tags.map((tag, index) => (
              <span key={index} className="tag" onClick={() => onTagClick(tag)}>
                {tag}
              </span>
            ))}
          </p>
          <p>Catatan: {catatan.length > 200 ? catatan.substring(0, 200) + '...' : catatan}</p>
          <div className="card-actions">
            <a href={url} target="_blank" rel="noopener noreferrer" className="view-button">
              <i className="fas fa-eye"></i> Buka
            </a>
            <button onClick={handleEditClick} className="edit-button">
              <i className="fas fa-pencil-alt"></i> Edit
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Card;