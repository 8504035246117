import React, { useState, useEffect } from 'react';
import CardList from './components/CardList';
import './styles.css';

function App() {
  const [cards, setCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);
  const [newArchive, setNewArchive] = useState({
    judul: '',
    ekstensi: '',
    url: '',
    tags: '',
    catatan: '',
  });

  useEffect(() => {
    fetch('https://sekretariatbackend.mahadsiber.my.id/data.json')
      .then(response => response.json())
      .then(data => {
        setCards(data);
        setFilteredCards(data);
      });
  }, []);

  const handleAddArchive = () => {
    setShowAddForm(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewArchive({
      ...newArchive,
      [name]: value,
    });
  };

  const handleSaveArchive = () => {
    const newCards = [...cards, {
      ...newArchive,
      tags: newArchive.tags.split(',').map(tag => tag.trim()),
    }];
    setCards(newCards);
    setFilteredCards(newCards);
    setShowAddForm(false);
    setNewArchive({
      judul: '',
      ekstensi: '',
      url: '',
      tags: '',
      catatan: '',
    });
    // Simpan perubahan ke data.json
    fetch('https://sekretariatbackend.mahadsiber.my.id/data.json', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newCards),
    });
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filtered = cards.filter(card => {
      const judulMatch = card.judul.toLowerCase().includes(query.toLowerCase());
      const tagsMatch = card.tags.some(tag => tag.toLowerCase().includes(query.toLowerCase()));
      const catatanMatch = card.catatan.toLowerCase().includes(query.toLowerCase());
      return judulMatch || tagsMatch || catatanMatch;
    });
    setFilteredCards(filtered);
  };

  return (
    <div className="App">
      <h1>Sekretariat MHS</h1>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Cari..."
          value={searchQuery}
          onChange={handleSearch}
        />
        <button className="search-button">
          <i className="fas fa-search"></i>
        </button>
      </div>
      <button className="add-archive-button" onClick={handleAddArchive}>
        <i className="fas fa-plus"></i> Tambahkan Arsip
      </button>
      {showAddForm && (
        <div className="add-form">
          <input
            type="text"
            name="judul"
            value={newArchive.judul}
            onChange={handleInputChange}
            placeholder="Judul"
          />
          <input
            type="text"
            name="ekstensi"
            value={newArchive.ekstensi}
            onChange={handleInputChange}
            placeholder="Ekstensi"
          />
          <input
            type="text"
            name="url"
            value={newArchive.url}
            onChange={handleInputChange}
            placeholder="URL"
          />
          <input
            type="text"
            name="tags"
            value={newArchive.tags}
            onChange={handleInputChange}
            placeholder="Tags (dipisahkan dengan koma)"
          />
          <textarea
            name="catatan"
            value={newArchive.catatan}
            onChange={handleInputChange}
            placeholder="Catatan"
          />
          <button onClick={handleSaveArchive}>Simpan</button>
        </div>
      )}
      <CardList cards={filteredCards} setCards={setCards} setFilteredCards={setFilteredCards} />
    </div>
  );
}

export default App;