import React from 'react';
import Card from './Card';

const CardList = ({ cards, setCards, setFilteredCards }) => {
  const handleEdit = (index, updatedData) => {
    const newCards = [...cards];
    newCards[index] = updatedData;
    setCards(newCards);
    setFilteredCards(newCards);
    // Simpan perubahan ke data.json
    fetch('/data.json', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newCards),
    });
  };

  const handleTagClick = (tag) => {
    setFilteredCards(cards.filter(card => card.tags.includes(tag)));
  };

  const handleExtensionClick = (extension) => {
    setFilteredCards(cards.filter(card => card.ekstensi === extension));
  };

  return (
    <div className="card-list">
      {cards.map((card, index) => (
        <Card
          key={index}
          {...card}
          onEdit={(updatedData) => handleEdit(index, updatedData)}
          onTagClick={handleTagClick}
          onExtensionClick={handleExtensionClick}
        />
      ))}
    </div>
  );
};

export default CardList;